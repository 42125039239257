@import "src/app/theme.module";

#footer {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img.logo {
    width: auto;
    max-width: 200px;
  }

  p.about {
    max-width: 490px;
    text-align: center;
    font-weight: 300;
    font-size: 0.9rem;
    margin-top: 2rem;
    line-height: 1.7em;
    padding: 0 20px;
    color: $footerSecondaryLight
  }

  ul.social-links {
    margin: 1.5rem 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  p.legal {
    text-align: center;
    font-weight: 300;
    font-size: 0.9rem;
    border-top: 1px solid var(--separator-color);
    align-self: stretch;
    padding: 2rem 20px;
    margin-top: 1rem;
  }

  .privacy {
    color: $footerSecondaryLight
  }

  .logo {
    width: 4rem;
    height: 4rem;
  }

  .logo-name {
    margin-left: 1rem;
    color: white;
  }
}

.dark-theme {
  #footer {
    background-color: $grapeMust;
    color: $white;
  }
}

@media screen and (min-width: $xs) {
  #footer {

    .links li {
      flex: 0 0 50%;
    }
  }
}

