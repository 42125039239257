@import "src/app/theme.module";
#gradientButton {
  height: 40px;
  position: relative;
  padding: 0;


  display: flex;
  align-items: center;
  justify-content: center;

  &.full-width {
    width: 100%;
  }

  .button {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    color: $black;
  }

  .solid-button {
    background-color: transparent;
    color: white;
  }
}

.dark-theme {
  #gradientButton {
    .button {
      color: $white;
    }
  }
  }
