@import "src/app/theme.module";

#home {


  .box-liquidity-info {
    margin-top: 2rem;
    padding: 5rem;
  }

  .liquidity-info {
    margin: 0 1rem;
    color: white;
    width: 50rem;
  }

  .input-row-container {
    display: flex;
    flex-direction: row
  }

  .btn-disabled {
    opacity: 0.5;
  }

  .text {
    color: white;
  }


  .title {
    color: white;
    font-weight: bold;
  }

  .btn-liquidity {
    width: 10rem;
    height: 50px;
  }

  .input-liquidity {
    width: 30rem
  }

  .input-button {
    align-items: flex-end;
  }

  .input-text-button {
    color: white;
  }

  .max-btn {
    border: none;
    background-color: transparent;
    color: white;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    height: 1.5rem;
  }

}

.dark-theme {
  #home {
    // background-color: $primaryDarkBackground;

    .actions-box > * {
      color: $white;
    }
  }
}

@media screen and (max-width: $xs) {
  #home {
    display: flex;
    justify-content: center;

    .title {
      text-align: center;
    }

    .description {
      text-align: center;
    }

    .box-liquidity-info {
      padding: 3rem 2rem;
      margin: 0 1rem;
    }

    .input-liquidity {
      width: 80%
    }

    .input-button {
      align-items: center;
    }

    .btn-liquidity {
      width: 80%;
      margin-top: 10px;
      height: 2rem;
    }

    .input-row-container {
      display: flex;
      flex-direction: column;
    }
  }
}
