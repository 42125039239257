@import "src/app/theme.module";

#UserMenu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 1.5rem;
  margin-right: 2rem;

  h5 {
    cursor: pointer;
  }

  .container {
    padding-top: 1rem;
    background-color: white;
    border-radius: 1.5rem;
    width: 10rem;
  }

  .walletIcon {
    width: 2vw;
    height: 2vw;
  }

  .text {
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
  }

  .iconButtonRow {
    display: flex;
    justify-self: center;
  }

  .dark-theme {
     .container {
      background-color: $grapeMust;
       color: $white;
    }
  }
}
