@import "src/app/theme.module";

#backgroundLines {
  flex: 1;
  bottom: 0;
  background-image: url("Frame.png");
  width: 100%;
  //width: 1200px;
  //background-size: cover;
  background-repeat: no-repeat;
  //background-position: 0 10rem;
  // min-height: calc(100vh - 6rem);
}


.dark-theme {
  #backgroundLines {
    background-image: url("background-lines.png"), $backgroundGradient;
  }
}
