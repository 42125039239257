@import "src/app/theme.module";

.error-boundary {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  h2 {
    margin: 0;
  }

  .error-code {
    font-size: 5rem;
    color: $secondaryColor;
  }
}

.dark-theme {
  .error-boundary {
    background-color: $grapeMust;
    color: $white;

    .error-code {
      color: $primaryColor;
    }
  }
}
