@import "src/app/theme.module";

#input-text {
  display: flex;
  align-items: center;
  position: relative;

  &.full {
    width: 100%;
  }

  &.stacked {
    flex-direction: column;
    align-items: stretch;
  }

  .input-icon {
    margin-right: 10px;
  }

  .input-label {
    font-weight: bold;
    font-size: .8rem;
    text-transform: uppercase;
  }

  &.stacked .input-label {
    margin-bottom: 10px;
  }

  input {
    flex: 1;
    border-radius: 25px;
    height: 50px;
    border: 1px solid $black;
    padding: 0 10px;
    outline: none;
    font-size: 20px;
  }

  input.disabled {
    cursor: not-allowed;
  }

  &.stacked input {
    flex: auto;
  }

  textarea {
    height: 100px;
    border-radius: 20px;
    border-color: #837f7f;
    padding: 10px;
    resize: none;
  }
  .error-message {
    color: red;
    font-size: .9rem;
    position: absolute;
    bottom: -20px;
    margin: 0;
  }
}

.dark-theme {
  #input-text {
    .input-label {
      color: $white
    }

    input {
      border-color: $footerSecondaryLight;
      background-color: $grapeMust;
      color: $white;
    }

    svg {
      color: $secondaryLightColor;
    }

  }
}
