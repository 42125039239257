@import "src/app/theme.module";

#authenticate {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    margin: auto;
    background-color: white;
    align-self: center;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    width: 80vw;
    padding: 3rem 10%;
    box-sizing: border-box;
  }

  .logo {
    height: 10rem;
    width: 10rem;
  }

  .inputText {
    margin-top: 2rem;
    width: 100%;
  }

  .buttonSignIn {
    margin-top: 3rem;
    width: 12rem;
  }
  .buttonSignInMetamask {
    margin-top: 3rem;
    width: 18rem;
  }

  .auth-error {
    margin-bottom: 0;
    color: $primaryColor;
    font-size: .9rem;
    text-align: center;
  }

  .dark-theme {
    .container {
      background-color: $primaryDarkBackground;
    }
  }
}

@media screen and (min-width: $xs) {
  #authenticate {
    .container {
      background-color: white;
      align-self: center;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-position: center;
      width: 30vw;
      padding: 3rem 10%;
    }
  }
}
