$primaryColor: #E60065;
$secondaryColor: #2660DE;
$secondaryLightColor: #90FFFF;
$footerSecondaryLight: #b9ebf7;
$primaryDarkBackground: #162B68; // #251e37;
$white: #FFF;
$black: #000;
$grapeMust: #2A2141;

$backgroundGradient: linear-gradient(to bottom, $grapeMust 15%, $primaryDarkBackground 70%, $grapeMust 90%);
$standardGradient: linear-gradient(to right, $secondaryColor, $primaryColor) border-box;
$fuchsiaTurquoiseGradient: linear-gradient(to right, $primaryColor, $secondaryLightColor 80%);

/* BREAKPOINTS */
$xs: 599px;
$sm: 1023px;
$md: 1439px;
$lg: 1919px;
$xl: 1920px;

:export {
  white: $white;
  grapeMust: $grapeMust;
  fuchsiaTurquoiseGradient: $fuchsiaTurquoiseGradient;
}
