@import "src/app/theme.module";

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0;
  height: 4rem;
  padding: 1rem;

  .header-logo-small {
    display: flex;
    flex: 1;
  }

  .circle {
    width: 40px;
    height: 40px;
    margin: 0 1rem;
  }

  .buttonSignIn {
    width: 5rem;
  }

  .buttonWallet {
    display: flex;
    justify-content: space-between;
    width: 8rem;
  }

  .walletButton {
    align-self: center;
    justify-items: center;
    display: flex;
    justify-content: space-between;

    &.balance {
      margin-left: 5px;
    }
  }

  .connectButton {
    align-self: center;
    justify-items: center;
    display: flex;
    justify-content: space-between;

    &.balance {
      margin-left: 5px;
    }
  }

  .walletButtonText {
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-self: center;
    margin: 0 0.5rem;
  }

  .image {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  .walletIcon {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }

  .logo {
    width: 6rem;
    height: 6rem;
  }

  .logo-name {
    margin-left: 1rem;
    color: white;
  }

}

.dark-theme {
  #header {
    background-color: $grapeMust;
  }
}

@media screen and (max-width: $xs) {
  #header {
    .image {
      display: none;
    }

    .logo-name {
      margin-left: 0.5rem;
      color: white;
      font-size: 1rem;
    }

    .walletButton  {
      display: none;
    }

    .header-logo-small {
      display: flex;
      flex: 0;
    }
  }
}
