@import "src/app/theme.module";

#connect {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  align-self: center;
  border-radius: 5px;
  width: 30vw;
  margin-top: 2rem;

  .walletIcon {
    width: 1.5vw;
    height: 1.5vw;

    &.wc {
      width: 1.8vw;
      height: 1.8vw;
    }
  }

  .connectButton {
    width: 80%;
    margin-top: 1rem;
    align-self: center;
    justify-items: center;
    display: flex;
    justify-content: space-between;
  }

  .btn-text {
    font-size: 1rem;
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-self: center;
    margin: 0 0.5rem;
  }


  .auth-error {
    margin-bottom: 0;
    color: $primaryColor;
    font-size: .9rem;
    text-align: center;
  }

  .light-theme {
  }
}

@media screen and (max-width: $xs) {
  #connect {

    .connectButton {
      width: 100%;
    }

    .btn-text {
      font-size: 0.8rem;
    }

    .walletIcon {
      width: 1.2rem;
      height: 1.2rem;

      &.wc {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

  }
}

