@import "src/app/theme.module";

.card {
  //position: relative;
  //background-color: $white;
  //box-shadow: 0 4px 3px -2px grey;
  background-color: $white;
  border-style: solid;
  border-color: $black;
  border-radius: 30px;
  font-weight: 400;

  &.card-border-thin {
    border-width: 1px;
  }

  &.card-border-medium {
    border-width: 3px;
  }

  &.card-border-thick {
    border-width: 5px;
  }

  &.card-gradient-border {
    background:
      linear-gradient(#fff, #fff) padding-box,
      $standardGradient;
    border-color: transparent;
  }

  &.card-gradient-solid {
    background:
      linear-gradient(transparent, transparent) padding-box,
      $standardGradient;
    border-color: transparent;
  }
}

h3 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.2rem;
}

.card-content::-webkit-scrollbar {
  height: 10px;
}

.card-content::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.card-content::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.dark-theme {
  .card {
    background-color: $primaryDarkBackground;
    border-color: $white;

    &.card-gradient-border,
    &.card-gradient-solid {
      background:
        linear-gradient($grapeMust, $grapeMust) padding-box,
        $standardGradient;
      border-color: transparent;
    }

    &.card-gradient-solid.active,
    &.card-hovered{
      background:
        linear-gradient(transparent, transparent) padding-box,
        $standardGradient;
      border-color: transparent;
    }
  }
  .card.fill {
    background-color: $primaryDarkBackground;
  }
  .card.blu-border {
    border-color: $secondaryLightColor;
  }
}

@media screen and (max-width: $xs) {
  .card.profile-card,
  .dark-theme .card.profile-card{
    background: none;
  }
}

@media screen and (min-width: $xs) {
}
