@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-family:'Kanit', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}#app{display:flex;flex-direction:column}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}#home{min-height:60vh;padding-bottom:6rem}#home .quickswap-link{color:hotpink;cursor:pointer}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#header{display:flex;align-items:center;justify-content:space-between;flex:0 1;height:4rem;padding:1rem}#header .header-logo-small{display:flex;flex:1 1}#header .circle{width:40px;height:40px;margin:0 1rem}#header .buttonSignIn{width:5rem}#header .buttonWallet{display:flex;justify-content:space-between;width:8rem}#header .walletButton{align-self:center;justify-items:center;display:flex;justify-content:space-between}#header .walletButton.balance{margin-left:5px}#header .connectButton{align-self:center;justify-items:center;display:flex;justify-content:space-between}#header .connectButton.balance{margin-left:5px}#header .walletButtonText{display:flex;justify-content:center;align-content:center;align-self:center}#header .content{display:flex;width:100%;justify-content:center;justify-items:center;align-self:center;margin:0 0.5rem}#header .image{width:3rem;height:3rem;cursor:pointer}#header .walletIcon{width:2rem;height:2rem;margin-left:1rem}#header .logo{width:6rem;height:6rem}#header .logo-name{margin-left:1rem;color:white}.dark-theme #header{background-color:#2A2141}@media screen and (max-width: 599px){#header .image{display:none}#header .logo-name{margin-left:0.5rem;color:white;font-size:1rem}#header .walletButton{display:none}#header .header-logo-small{display:flex;flex:0 1}}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}.card{background-color:#fff;border-style:solid;border-color:#000;border-radius:30px;font-weight:400}.card.card-border-thin{border-width:1px}.card.card-border-medium{border-width:3px}.card.card-border-thick{border-width:5px}.card.card-gradient-border{background:linear-gradient(#fff, #fff) padding-box,linear-gradient(to right, #2660DE, #E60065) border-box;border-color:transparent}.card.card-gradient-solid{background:linear-gradient(transparent, transparent) padding-box,linear-gradient(to right, #2660DE, #E60065) border-box;border-color:transparent}h3{font-size:1.5rem}h2{font-size:1.2rem}.card-content::-webkit-scrollbar{height:10px}.card-content::-webkit-scrollbar-track{background-color:#e4e4e4;border-radius:100px}.card-content::-webkit-scrollbar-thumb{border-radius:100px;background-image:linear-gradient(180deg, #d0368a 0%, #708ad4 99%);box-shadow:inset 2px 2px 5px 0 rgba(255,255,255,0.5)}.dark-theme .card{background-color:#162B68;border-color:#fff}.dark-theme .card.card-gradient-border,.dark-theme .card.card-gradient-solid{background:linear-gradient(#2A2141, #2A2141) padding-box,linear-gradient(to right, #2660DE, #E60065) border-box;border-color:transparent}.dark-theme .card.card-gradient-solid.active,.dark-theme .card.card-hovered{background:linear-gradient(transparent, transparent) padding-box,linear-gradient(to right, #2660DE, #E60065) border-box;border-color:transparent}.dark-theme .card.fill{background-color:#162B68}.dark-theme .card.blu-border{border-color:#90FFFF}@media screen and (max-width: 599px){.card.profile-card,.dark-theme .card.profile-card{background:none}}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#gradientButton{height:40px;position:relative;padding:0;display:flex;align-items:center;justify-content:center}#gradientButton.full-width{width:100%}#gradientButton .button{width:100%;height:100%;border-radius:inherit;color:#000}#gradientButton .solid-button{background-color:transparent;color:white}.dark-theme #gradientButton .button{color:#fff}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#button{border-style:solid;border-width:2px;border-color:#90FFFF;height:40px;min-width:100px;border-radius:20px !important;background-color:#fff;display:flex;align-items:center;justify-content:center}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#authenticate{display:flex;align-items:center;justify-content:center}#authenticate .container{margin:auto;background-color:white;align-self:center;border-radius:5px;background-repeat:no-repeat;background-position:center;width:80vw;padding:3rem 10%;box-sizing:border-box}#authenticate .logo{height:10rem;width:10rem}#authenticate .inputText{margin-top:2rem;width:100%}#authenticate .buttonSignIn{margin-top:3rem;width:12rem}#authenticate .buttonSignInMetamask{margin-top:3rem;width:18rem}#authenticate .auth-error{margin-bottom:0;color:#E60065;font-size:.9rem;text-align:center}#authenticate .dark-theme .container{background-color:#162B68}@media screen and (min-width: 599px){#authenticate .container{background-color:white;align-self:center;border-radius:5px;background-repeat:no-repeat;background-position:center;width:30vw;padding:3rem 10%}}



:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#connect{display:flex;align-items:center;justify-content:center;background-color:transparent;align-self:center;border-radius:5px;width:30vw;margin-top:2rem}#connect .walletIcon{width:1.5vw;height:1.5vw}#connect .walletIcon.wc{width:1.8vw;height:1.8vw}#connect .connectButton{width:80%;margin-top:1rem;align-self:center;justify-items:center;display:flex;justify-content:space-between}#connect .btn-text{font-size:1rem}#connect .content{display:flex;width:100%;justify-content:center;justify-items:center;align-self:center;margin:0 0.5rem}#connect .auth-error{margin-bottom:0;color:#E60065;font-size:.9rem;text-align:center}@media screen and (max-width: 599px){#connect .connectButton{width:100%}#connect .btn-text{font-size:0.8rem}#connect .walletIcon{width:1.2rem;height:1.2rem}#connect .walletIcon.wc{width:1.5rem;height:1.5rem}}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#UserMenu{display:flex;align-items:flex-start;justify-content:flex-end;margin-top:1.5rem;margin-right:2rem}#UserMenu h5{cursor:pointer}#UserMenu .container{padding-top:1rem;background-color:white;border-radius:1.5rem;width:10rem}#UserMenu .walletIcon{width:2vw;height:2vw}#UserMenu .text{display:flex;justify-content:center;align-content:center;align-self:center}#UserMenu .iconButtonRow{display:flex;justify-self:center}#UserMenu .dark-theme .container{background-color:#2A2141;color:#fff}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#footer{width:100%;padding:20px 0;display:flex;flex-direction:column;align-items:center}#footer img.logo{width:auto;max-width:200px}#footer p.about{max-width:490px;text-align:center;font-weight:300;font-size:0.9rem;margin-top:2rem;line-height:1.7em;padding:0 20px;color:#b9ebf7}#footer ul.social-links{margin:1.5rem 0 0;padding:0;list-style:none;display:flex;justify-content:center}#footer ul.social-links li:not(:first-child){margin-left:1rem}#footer p.legal{text-align:center;font-weight:300;font-size:0.9rem;border-top:1px solid var(--separator-color);align-self:stretch;padding:2rem 20px;margin-top:1rem}#footer .privacy{color:#b9ebf7}#footer .logo{width:4rem;height:4rem}#footer .logo-name{margin-left:1rem;color:white}.dark-theme #footer{background-color:#2A2141;color:#fff}@media screen and (min-width: 599px){#footer .links li{flex:0 0 50%}}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#socialLink .container{border:1px solid white;padding:5px;width:20px;height:20px;border-radius:50%;display:flex}#socialLink .icon{width:15px;height:15px}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#backgroundLines{flex:1 1;bottom:0;background-image:url(/static/media/Frame.9de4725a.png);width:100%;background-repeat:no-repeat}.dark-theme #backgroundLines{background-image:url(/static/media/background-lines.0d6c2366.png),linear-gradient(to bottom, #2A2141 15%, #162B68 70%, #2A2141 90%)}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#home .box-liquidity-info{margin-top:2rem;padding:5rem}#home .liquidity-info{margin:0 1rem;color:white;width:50rem}#home .input-row-container{display:flex;flex-direction:row}#home .btn-disabled{opacity:0.5}#home .text{color:white}#home .title{color:white;font-weight:bold}#home .btn-liquidity{width:10rem;height:50px}#home .input-liquidity{width:30rem}#home .input-button{align-items:flex-end}#home .input-text-button{color:white}#home .max-btn{border:none;background-color:transparent;color:white;justify-content:flex-end;margin:0;padding:0;height:1.5rem}.dark-theme #home .actions-box>*{color:#fff}@media screen and (max-width: 599px){#home{display:flex;justify-content:center}#home .title{text-align:center}#home .description{text-align:center}#home .box-liquidity-info{padding:3rem 2rem;margin:0 1rem}#home .input-liquidity{width:80%}#home .input-button{align-items:center}#home .btn-liquidity{width:80%;margin-top:10px;height:2rem}#home .input-row-container{display:flex;flex-direction:column}}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#search{height:2.5rem;position:relative;width:100%}#search .searchInput{height:100%;border-width:0;padding:0;width:100%;border-radius:30px;outline:none;text-align:center;font-size:1rem}#search .search-icon{position:absolute;color:#E60065 !important;right:10px;height:100%}@media screen and (min-width: 1023px){#search{width:30%;min-width:300px}}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#input-text{display:flex;align-items:center;position:relative}#input-text.full{width:100%}#input-text.stacked{flex-direction:column;align-items:stretch}#input-text .input-icon{margin-right:10px}#input-text .input-label{font-weight:bold;font-size:.8rem;text-transform:uppercase}#input-text.stacked .input-label{margin-bottom:10px}#input-text input{flex:1 1;border-radius:25px;height:50px;border:1px solid #000;padding:0 10px;outline:none;font-size:20px}#input-text input.disabled{cursor:not-allowed}#input-text.stacked input{flex:auto}#input-text textarea{height:100px;border-radius:20px;border-color:#837f7f;padding:10px;resize:none}#input-text .error-message{color:red;font-size:.9rem;position:absolute;bottom:-20px;margin:0}.dark-theme #input-text .input-label{color:#fff}.dark-theme #input-text input{border-color:#b9ebf7;background-color:#2A2141;color:#fff}.dark-theme #input-text svg{color:#90FFFF}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}#select{display:flex;align-items:center;margin-bottom:20px;position:relative}#select .ibz-select [class*="-menu"]{background-color:#fff;overflow-x:hidden}#select .ibz-select [class*="-MenuList"]::-webkit-scrollbar{width:7px}#select .ibz-select [class*="-MenuList"]::-webkit-scrollbar-track{background-color:#e4e4e4;border-radius:100px}#select .ibz-select [class*="-MenuList"]::-webkit-scrollbar-thumb{border-radius:100px;background-image:linear-gradient(180deg, #162B68, #162B68);box-shadow:inset 2px 2px 5px 0 rgba(255,255,255,0.5)}#select .ibz-select>div{border-radius:20px;border:1px solid #000}#select.stacked{flex-direction:column;align-items:stretch}#select .input-label{font-weight:bold;font-size:.8rem;text-transform:uppercase}#select.stacked .input-label{margin-bottom:10px}#select .error-message{color:red;font-size:.9rem;position:absolute;bottom:-20px;margin:0}.dark-theme #select .input-label{color:#fff}.dark-theme #select .ibz-select [class*="-placeholder"]{color:#fff}.dark-theme #select .ibz-select [class*="-singleValue"]{color:#fff}.dark-theme #select .ibz-select [class*="-menu"]{background-color:#fff;overflow-x:hidden}.dark-theme #select .ibz-select>div{border-radius:20px;background-color:#2A2141;border:1px solid #90FFFF}.dark-theme #select input.blu-border{border-color:#90FFFF;background-color:transparent;color:#fff}

:export{white:#fff;grapeMust:#2A2141;fuchsiaTurquoiseGradient:linear-gradient(to right, #E60065, #90FFFF 80%)}.error-boundary{flex:1 1;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:3rem 0}.error-boundary h2{margin:0}.error-boundary .error-code{font-size:5rem;color:#2660DE}.dark-theme .error-boundary{background-color:#2A2141;color:#fff}.dark-theme .error-boundary .error-code{color:#E60065}


