@import "src/app/theme.module";

#button {
  border-style: solid;
  border-width: 2px;
  border-color: $secondaryLightColor;
  height: 40px;
  min-width: 100px;
  border-radius: 20px !important;

  background-color: $white;

  display: flex;
  align-items: center;
  justify-content: center;
}

