@import "src/app/theme.module";

#select {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  .ibz-select {
    [class*="-menu"] {
      background-color: $white;
      overflow-x: hidden;
    }

    [class*="-MenuList"] {
      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-image: linear-gradient(180deg, $primaryDarkBackground, $primaryDarkBackground);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
      }
    }

    & > div {
      border-radius: 20px;
      border: 1px solid $black;
    }
  }

  &.stacked {
    flex-direction: column;
    align-items: stretch;
  }

  .input-label {
    font-weight: bold;
    font-size: .8rem;
    text-transform: uppercase;
  }

  &.stacked .input-label {
    margin-bottom: 10px;
  }

  .error-message {
    color: red;
    font-size: .9rem;
    position: absolute;
    bottom: -20px;
    margin: 0;
  }
}

.dark-theme {
  #select {
    .input-label {
      color: $white
    }

    .ibz-select {
      [class*="-placeholder"] {
        color: $white;
      }
      [class*="-singleValue"] {
        color: $white;
      }
      [class*="-menu"] {
        background-color: $white;
        overflow-x: hidden;
      }

       & > div {
        border-radius: 20px;
        background-color: $grapeMust;
        border: 1px solid $secondaryLightColor;
      }
    }

    input.blu-border {
      border-color: $secondaryLightColor;
      background-color: transparent;
      color: $white;
    }
  }
}
