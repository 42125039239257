@import "src/app/theme.module";

#search {
  height: 2.5rem;
  // margin: 0 auto;
  position: relative;
  width: 100%;

  .searchInput {
    height: 100%;
    border-width: 0;
    padding: 0;
    width: 100%;
    border-radius: 30px;
    outline: none;
    text-align: center;
    font-size: 1rem;
  }

  .search-icon {
    position: absolute;
    color: $primaryColor !important;
    right: 10px;
    height: 100%;
  }

}

@media screen and (min-width: $sm) {
  #search {
    width: 30%;
    min-width: 300px;
  }
}
