@import "src/app/theme.module";
#socialLink {

  .container {
    border: 1px solid white;
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
  }

  .icon {
    width: 15px;
    height: 15px
  }
}
